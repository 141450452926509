import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Carousel from '../components/carousel'
import SEO from '../components/seo'

export const query = graphql`
query($slug: String!){
  project: strapiProject(slug: {eq: $slug}) {
    slug
    name
    childStrapiProjectDescriptionTextnode {
      description
    }
    cover {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    images {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}
`
export default function Project({ data }) {
  const project = data.project;
  return (
    <Layout>
      <SEO 
        title={project.name}
      />
      <section>
        <div class="relative mx-auto container">
          <div class="flex flex-col lg:flex-row items-center gap-6 lg:gap-12 w-full">
            <div className="w-full lg:w-2/3">
              {project.images ?
                <Carousel images={project.images} />
                :
                <GatsbyImage image={getImage(project?.cover?.localFile)} />
              }
            </div>
            <div class="w-full lg:w-1/3 mb-28">
              <div class="flex justify-between">
                  <h1 class="text-4xl font-playFair">{project.name}</h1>
              </div>
              <div class="max-w-none">
                <p>{project?.childStrapiProjectDescriptionTextnode?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
